// import { getStrapiImage, getStrapiImagePlaceholder } from '@/lib/api'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Image from 'next/image'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import useStore from '@/hooks/useStore'
import { getStrapiImage, getStrapiImagePlaceholder } from '@/lib/api'
import { useRouter } from 'next/router'

const RegisterCta = () => {
  const data = useStore((state) => state.home?.callToAction) as RegisterCta
  const { push } = useRouter()
  return (
    <Box
      id="achievement"
      sx={{
        py: 8,
        bgcolor: 'primary.main',
        position: 'relative',
        '&::after': {
          display: 'block',
          width: 1,
          height: 1,
          content: '""',
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          background: 'linear-gradient(90deg, rgba(0,0,0,0.7) 40%,transparent)',
        },
      }}
    >
      {data?.media && (
        <Image
          src={getStrapiImage(data?.media)}
          blurDataURL={getStrapiImagePlaceholder(data?.media)}
          placeholder="blur"
          alt={data?.media?.alternativeText}
          layout="fill"
          objectFit="cover"
        />
      )}
      <Container sx={{ position: 'relative', zIndex: 1 }}>
        <Grid container spacing={12}>
          <Grid item xs={12} md={6}>
            <Stack spacing={4} sx={{ minHeight: 400 }} justifyContent="center">
              <Typography variant="h4" fontWeight={700} color="#fff">
                {data?.title}
              </Typography>
              <Typography color="#d8d8d8">{data?.description}</Typography>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  endIcon={<ArrowForwardIcon />}
                  onClick={() => push(data?.button?.path)}
                  sx={{ px: 6, py: 2, borderRadius: 50 }}
                >
                  {data?.button?.label}
                </Button>
              </div>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}></Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default RegisterCta
